import React from 'react';

import ExamDetailsAccordion from '../Exam/ExamDetailsAccordion';

/** @typedef {import('../../types').LineItem} LineItem */
/** @typedef {import('../../types').Order} Order */
/** @typedef {import('../../types').PaymentInformation} PaymentInformation */

/**
 * @typedef OrderDetailsProps
 * @property {Order} order
 * @property {LineItem[]} lineItems
 * @property {PaymentInformation} [paymentInformation]
 * @property {boolean} userWasCharged
 */

/**
 * @param {OrderDetailsProps} props
 * @returns {React.ReactElement}
 */
export default function OrderDetails(props) {
  return (
    <div className="card h-auto">
      <div className="card-header bg-dark text-white">
        <h2 className="fs-5 mb-0">Order #{props.order.id}</h2>
      </div>

      <ul className="list-group list-group-flush">
        {props.lineItems.map((lineItem) => (
          <LineItemDetails
            key={lineItem.id}
            lineItem={lineItem}
            userWasCharged={props.userWasCharged}
          />
        ))}
      </ul>

      {props.userWasCharged && (
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <span className="font-weight-bold">
              {polyglot.t('order_receipt_order_total')}
            </span>
            <span className="font-weight-bold">{props.order.total}</span>
          </div>
        </div>
      )}

      {props.paymentInformation && (
        <PaymentInformationDetails
          paymentInformation={props.paymentInformation}
        />
      )}
    </div>
  );
}

/**
 * @param {Object} props
 * @param {LineItem} props.lineItem
 * @param {boolean} props.userWasCharged
 * @returns {React.ReactElement}
 */
function LineItemDetails({ lineItem, userWasCharged }) {
  return (
    <li
      key={lineItem.id}
      className="py-3 list-group-item d-flex flex-column"
      style={{ gap: '0.5rem' }}
    >
      {lineItem.type === 'Fulfillment' ? (
        <FulfillmentDetails
          lineItem={lineItem}
          userWasCharged={userWasCharged}
        />
      ) : (
        <OtherLineItemDetails
          lineItem={lineItem}
          userWasCharged={userWasCharged}
        />
      )}
    </li>
  );
}

/**
 * @param {Object} props
 * @param {LineItem} props.lineItem
 * @param {boolean} props.userWasCharged
 * @returns {React.ReactElement}
 */
function FulfillmentDetails({ lineItem, userWasCharged }) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div
          className="d-flex align-items-center"
          style={{
            gap: '1rem',
            // NOTE: this is important for the ellipsis to work
            // https://css-tricks.com/flexbox-truncated-text/
            minWidth: '0',
            maxWidth: 'calc(100% - 4rem)',
          }}
        >
          {lineItem.fulfillment?.serviceLine && (
            <span className="badge badge-dark">
              {lineItem.fulfillment?.serviceLine}
            </span>
          )}

          <span
            className="font-weight-bold"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {lineItem.fulfillment?.examName}
          </span>
        </div>

        {userWasCharged && (
          <div className="font-weight-bold">{lineItem.amountTotal}</div>
        )}
      </div>

      {lineItem.fulfillment && (
        <ExamDetailsAccordion fulfillment={lineItem.fulfillment} />
      )}
    </>
  );
}

/**
 * @param {Object} props
 * @param {LineItem} props.lineItem
 * @param {boolean} props.userWasCharged
 * @returns {React.ReactElement}
 */
function OtherLineItemDetails({ lineItem, userWasCharged }) {
  return (
    <div className="d-flex justify-content-between">
      {['AccessCode', 'VoucherCode'].includes(lineItem.type) ? (
        <div>
          {lineItem.type === 'AccessCode'
            ? polyglot.t('order_receipt_access_code')
            : polyglot.t('order_receipt_voucher_code')}
        </div>
      ) : (
        <div>{lineItem.name}</div>
      )}

      {userWasCharged && (
        <div className="font-weight-bold">{lineItem.amountTotal}</div>
      )}
    </div>
  );
}

/**
 * @param {Object} props
 * @param {PaymentInformation} [props.paymentInformation]
 * @returns {React.ReactElement}
 */
function PaymentInformationDetails({
  paymentInformation: {
    cardBrand,
    paymentMethod,
    cardName,
    billingStreet,
    billingCityState,
    billingZip,
  },
}) {
  return (
    <div className="card-body d-flex flex-column" style={{ gap: '1.5rem' }}>
      <div>
        <p className="fs-6 mb-0 text-muted">
          <i className="fa-solid fa-credit-card mr-2"></i>
          {polyglot.t('order_receipt_payment_method')}
        </p>
        {cardBrand && paymentMethod ? (
          <p className="mb-0">
            {cardBrand} - #{paymentMethod}
          </p>
        ) : (
          <p className="mb-0">{polyglot.t('order_receipt_credit')}</p>
        )}
      </div>

      {cardName && billingStreet && billingCityState && billingZip && (
        <div>
          <p className="fs-6 mb-0 text-muted">
            <i className="fa-solid fa-map-location mr-2"></i>
            {polyglot.t('order_receipt_billing_address')}
          </p>
          <div>
            <p className="mb-0">{cardName}</p>
            <p className="mb-0">{billingStreet}</p>
            <p className="mb-0">{billingCityState}</p>
            <p className="mb-0">{billingZip}</p>
          </div>
        </div>
      )}
    </div>
  );
}
