import React, { useEffect, useState } from 'react';

import SecondCameraLaunchCard from './SecondCameraLaunchCard';
import SecondCameraPositioningCard from './SecondCameraPositioningCard';
import usePrechecksApi from './hooks/usePrechecksApi';

const GUARDIAN_NAMESPACE = 'PU_Second_Camera_Scan';

export default function SecondCameraScanApp({ precheckData }) {
  const { sendEvent, broadcastMessage } = usePrechecksApi(
    precheckData.fulfillment.uuid,
  );
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    // NOTE: Guardian can call this function to notify the app that the second camera is connected
    window[GUARDIAN_NAMESPACE] = {
      onConnect: () => {
        console.log('Connected');
        setIsConnected(true);
      },
    };

    return () => {
      window[GUARDIAN_NAMESPACE].onConnect = null;
    };
  }, []);

  if (isConnected) {
    return (
      <SecondCameraPositioningCard
        sendEvent={sendEvent}
        broadcastMessage={broadcastMessage}
      />
    );
  }

  return (
    <SecondCameraLaunchCard
      id="precheck-card-page-0"
      precheckData={precheckData}
      sendEvent={sendEvent}
      title={polyglot.t('prechecks_second_camera_launch_disconnected')}
      bodyText={polyglot.t('prechecks_second_camera_reconnect_instructions')}
    />
  );
}
