import { useEffect, useState } from 'react';

/**
 * @typedef CountdownOptions
 * @property {number} [interval] - The interval in milliseconds at which the
 *   countdown should update (defaults to 1000ms or 1 second).
 * @property {(timeLeft: number) => void} [onTick] - A callback function to be
 *   executed on each tick of the countdown.
 * @property {() => void} [onComplete] A callback function to be executed when
 *   the countdown reaches zero.
 */

/**
 * @summary Create countdown timers using `useCountdown`.
 * @description The `useCountdown` hook is useful for creating a countdown
 *   timer. By specifying an `endTime` and various `options` such as the
 *   interval between ticks and callback functions for each tick and completion,
 *   the hook sets up an interval that updates the count and triggers the
 *   appropriate callbacks until the countdown reaches zero. The countdown value
 *   is returned, allowing you to easily incorporate and display the countdown
 *   in your components.
 * @note This is a custom implementation of the `useCountdown` hook from the
 *   https://github.com/uidotdev/usehooks repository, as the original
 *   implementation by the [ui.dev](https://github.com/uidotdev) team depends on
 *   React's experimental `useEffectEvent` hook, which is not available in
 *   stable versions of React yet. Therefore, because this custom hook may not
 *   always produce the same behavior as the original hook, extra caution should
 *   be exercised when using this hook. If the original hook's implementation is
 *   ever updated so that it is in fact usable by us, then this custom hook
 *   should be replaced and all usages should be updated appropriately.
 * @author [Meazure Learning](https://www.meazurelearning.com)
 * @see [`useCountdown` documentation](https://usehooks.com/usecountdown)
 * @see [`useCountdown` source code](https://github.com/uidotdev/usehooks/blob/v2.4.1-experimental.1/index.js#L193)
 * @param {number} endTime - The timestamp in milliseconds when the countdown should end.
 * @param {CountdownOptions} [options={}] - An object containing optional parameters.
 * @returns {number} The `timeLeft` state variable, which stores the remaining
 *   time in milliseconds.
 */
export default function useCountdown(endTime, options = {}) {
  const { interval = 1000, onTick, onComplete } = options;

  const getTimeLeft = () => Math.max(0, endTime - Date.now());

  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    let newTimeLeft = getTimeLeft();
    setTimeLeft(newTimeLeft);

    if (newTimeLeft <= 0) {
      onComplete?.();
      return;
    }

    const intervalId = setInterval(() => {
      newTimeLeft = getTimeLeft();
      setTimeLeft(newTimeLeft);

      onTick?.(newTimeLeft);

      if (newTimeLeft <= 0) {
        clearInterval(intervalId);
        onComplete?.();
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [endTime, interval]);

  return timeLeft;
}
