import React from 'react';

import { ReservationActions } from './ReservationActions';
import { ReservationListItem } from './ReservationListItem';

/**
 * @typedef UnscheduledExamsProps
 * @property {Reservation[]} reservations
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {string[]} cancellationRequiredIds
 * @property {string} advanceCancellationReasonId
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {boolean} isDevelopmentEnv
 */

/**
 * @param {UnscheduledExamsProps} props
 * @returns {React.ReactElement}
 */
export const UnscheduledExams = ({
  reservations,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  advanceCancellationReasonId,
  guardianBrowserPlatform,
  isDevelopmentEnv,
}) => {
  if ((reservations?.length ?? 0) === 0) {
    return <></>;
  }

  return (
    <div className="card">
      <div className="card-header bg-dark text-white">
        <div className="fs-4 font-weight-bold">
          {polyglot.t('my_sessions_v2_unscheduled_exams')}
        </div>
      </div>

      <ul className="list-group list-group-flush border">
        {reservations.map((reservation) => (
          <li className="list-group-item" key={reservation.id}>
            <ReservationListItem reservation={reservation}>
              <ReservationActions
                reservation={reservation}
                studentReservationUrl={studentReservationUrl}
                studentOrderPath={studentOrderPath}
                cancellationRequiredIds={cancellationRequiredIds}
                advanceCancellationReasonId={advanceCancellationReasonId}
                guardianBrowserPlatform={guardianBrowserPlatform}
                isDevelopmentEnv={isDevelopmentEnv}
              />
            </ReservationListItem>
          </li>
        ))}
      </ul>
    </div>
  );
};
