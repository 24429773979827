import SlotsUrlBuilder from './SlotsUrlBuilder';
import axios from 'axios';

const SLOT_BUTTON_IDS = [
  '#js-reservation-reset-beginning',
  '#js-reservation-next-selection',
  '#js-find-slots'
]

class Slots {
  constructor() {
    this.reservationContent = document.querySelector('#reservation-content')
    this.loading = document.querySelector('.loading')
    this.slots = document.querySelector('.slots')
    this.slotsContainer = document.querySelector('.slots-container');
  }

  init() {
    if(this.slots) {
      this.slots.classList.add('d-none');
      this.slotsContainer.classList.add('d-none');
      this.disableButtons();
      this.loading.classList.remove('d-none');

      setTimeout(() => {
        axios.get(new SlotsUrlBuilder().init(), {contentType: 'application/json'})
        .then(response => {
          while(this.slots.firstChild){ this.slots.removeChild(this.slots.firstChild); }
          this.slots.innerHTML = response.data;
          this.loading.classList.add('d-none');
          this.slots.classList.remove('d-none');
          this.slotsContainer.classList.remove('d-none');
          this.enableButtons();
        }).catch(error => {
        });
      }, parseInt(this.reservationContent.getAttribute('data-slots-request-delay')));
    }
  }

  disableButtons() {
    SLOT_BUTTON_IDS.forEach(button_id => {
      const button = document.querySelector(button_id)
      if(button) { button.classList.add('disabled') }
    })
  }

  enableButtons() {
    SLOT_BUTTON_IDS.forEach(button_id=> {
      const button = document.querySelector(button_id)
      if(button) { button.classList.remove('disabled') }
    })
  }
}

 export default Slots;
