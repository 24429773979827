import DOMPurify from 'dompurify';
import React from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from 'react-bootstrap';

/**
 * @returns {React.ReactElement}
 */
const Guidelines = () => {
  return (
    <>
      <Row className="justify-content-center">
        <Col sm={4}>
          <Image
            src="/assets/stay-seated-04.svg"
            className="mx-auto d-block"
            alt=""
          />
          <p className="text-center">
            {polyglot.t('my_sessions_v2_exam_rules_stay_seated')}
          </p>
        </Col>
        <Col md="auto" />
        <Col sm={4}>
          <Image
            src="/assets/stay-in-frame-01.svg"
            className="mx-auto d-block"
            alt=""
          />
          <p className="text-center">
            {polyglot.t('my_sessions_v2_exam_rules_stay_in_frame')}
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={4}>
          <Image
            src="/assets/stay-quiet-02.svg"
            className="mx-auto d-block"
            alt=""
          />
          <p className="text-center">
            {polyglot.t('my_sessions_v2_exam_rules_stay_quiet')}
          </p>
        </Col>
        <Col md="auto" />
        <Col sm={4}>
          <Image
            src="/assets/stay-private-03.svg"
            className="mx-auto d-block"
            alt=""
          />
          <p className="text-center">
            {polyglot.t('my_sessions_v2_exam_rules_stay_private')}
          </p>
        </Col>
      </Row>
    </>
  );
};

/**
 * @typedef RulesProps
 * @property {SessionHistoryItem} reservation
 */

/**
 * @param {RulesProps} props
 * @returns {React.ReactElement}
 */
const Rules = ({ reservation }) => {
  return reservation.examRulesDisplaySecuritySettings ? (
    <>
      <p className="text-muted">
        {polyglot.t('my_sessions_v2_exam_rules_review_rules')}
      </p>
      <ul className="ps-1">
        {reservation.iteration.browser_tabs === 'restricted' && (
          <li>
            {polyglot.t('my_sessions_v2_exam_rules_close_all_browser_tabs')}
          </li>
        )}
        {reservation.iteration.allow_copy_paste !== 'enabled' && (
          <li>{polyglot.t('my_sessions_v2_exam_rules_no_copy_text_image')}</li>
        )}
        {reservation.iteration.window_size_allowed === 'maximized' && (
          <li>{polyglot.t('my_sessions_v2_exam_rules_window_maximized')}</li>
        )}
        {reservation.iteration.window_size_allowed === 'fullscreen' && (
          <li>{polyglot.t('my_sessions_v2_exam_rules_window_fullscreen')}</li>
        )}
        {reservation.iteration.lost_focus === 'restricted' && (
          <li>{polyglot.t('my_sessions_v2_exam_rules_no_switch_app')}</li>
        )}
      </ul>
    </>
  ) : (
    <p className="small">
      {polyglot.t('my_sessions_v2_exam_rules_review_other_guidelines')}
    </p>
  );
};

/**
 * @typedef AllowedResourcesProps
 * @property {string[]} allowedResources
 */

/**
 * @param {AllowedResourcesProps} props
 * @returns {React.ReactElement}
 */
const AllowedResources = ({ allowedResources }) => {
  return (
    <>
      {allowedResources?.length > 0 ? (
        <p className="small">
          {polyglot.t('my_sessions_v2_exam_rules_allowed_following_resources')}
        </p>
      ) : (
        <p>
          {polyglot.t('my_sessions_v2_exam_rules_allowed_external_resources')}
        </p>
      )}
      <ul>
        {allowedResources?.map((resource) => (
          <li key={resource}>{resource}</li>
        ))}
      </ul>
    </>
  );
};

/**
 * @typedef AccommodationsProps
 * @property {SessionHistoryItem} reservation
 */

/**
 * @param {AccommodationsProps} props
 * @returns {React.ReactElement}
 */
const Accommodations = ({ reservation }) => {
  const hasAccommodations = reservation.accommodations.length > 0;

  return (
    <>
      <ul>
        {reservation.displayDurationModifier && (
          <li>
            {polyglot.t('my_sessions_v2_exam_rules_total_allowed_duration', {
              duration: reservation.totalDurationFormatted,
            })}
          </li>
        )}
        {hasAccommodations &&
          reservation.accommodations.map((accommodation) => (
            <li key={accommodation}>{accommodation}</li>
          ))}
      </ul>

      {!hasAccommodations &&
        (reservation.iteration.isLivePlus
          ? polyglot.t('my_sessions_v2_exam_rules_proctor_verify_accommodation')
          : polyglot.t('my_sessions_v2_exam_rules_no_accommodations'))}
    </>
  );
};

/**
 * @typedef ExamRulesModalProps
 * @property {SessionHistoryItem} reservation
 * @property {boolean} show
 * @property {() => void} handleCloseModal
 */

/**
 * @param {ExamRulesModalProps} props
 * @returns {React.ReactElement}
 */
export const ExamRulesModal = ({ reservation, show, handleCloseModal }) => {
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      size="xl"
      className="p-0"
      centered
    >
      <Modal.Header>
        <Container fluid>
          <Row>
            <Col md={1} className="mt-2">
              <Image
                src="/assets/wordmark.svg"
                alt="ProctorU logo"
                fluid
                className="float-start"
              />
            </Col>
            <Col md={10} className="text-center">
              <Modal.Title>
                {polyglot.t('my_sessions_v2_exam_rules_exam_rule_check')}
              </Modal.Title>
            </Col>
            <Col md={1} className="mt-2">
              <Button
                variant="link"
                onClick={handleCloseModal}
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col sm={4} className="d-flex mb-4">
              <Card className="h-100 flex-fill">
                <Card.Header>
                  {polyglot.t('my_sessions_v2_exam_rules_guidelines')}
                </Card.Header>
                <Card.Body>
                  <Guidelines />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className="d-flex mb-4">
              <Card className="h-100 flex-fill">
                <Card.Header>
                  {polyglot.t('my_sessions_v2_exam_rules_rules')}
                </Card.Header>
                <Card.Body>
                  <Rules reservation={reservation} />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className="d-flex mb-4">
              <Card className="h-100 flex-fill">
                <Card.Header>
                  {polyglot.t('my_sessions_v2_exam_rules_allowed_resources')}
                </Card.Header>
                <Card.Body>
                  <AllowedResources
                    allowedResources={reservation.iteration.allowedResources}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="d-flex mb-4">
              <Card className="h-100 flex-fill">
                <Card.Header>
                  {polyglot.t('my_sessions_v2_exam_rules_accommodations')}
                </Card.Header>
                <Card.Body>
                  <Accommodations reservation={reservation} />
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className="d-flex mb-4">
              <Card className="h-100 flex-fill">
                <Card.Header>
                  {polyglot.t('my_sessions_v2_exam_rules_breaks')}
                </Card.Header>
                <Card.Body>
                  {reservation.iteration?.bathroomBreaks ? (
                    <p>
                      {polyglot.t('my_sessions_v2_exam_rules_breaks_allowed')}
                    </p>
                  ) : (
                    <p>
                      {polyglot.t(
                        'my_sessions_v2_exam_rules_no_breaks_allowed',
                      )}
                    </p>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className="d-flex mb-4">
              <Card className="h-100 flex-fill">
                <Card.Header>
                  {polyglot.t('my_sessions_v2_exam_rules_other_resources')}
                </Card.Header>
                <Card.Body>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        reservation.iteration.otherResources,
                      ),
                    }}
                  ></div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mx-auto float-none p-1">
              <Button
                variant="primary"
                block
                onClick={handleCloseModal}
                data-type="ExamRulesClose"
                data-fulfillment-uuid={reservation.fulfillment?.uuid}
                data-behavior="event-button"
                data-data="yes"
                data-dismiss="modal"
              >
                {polyglot.t('my_sessions_v2_exam_rules_close')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
