class MobileView {
  constructor() {
    this.findSlotsButton = document.querySelector('#js-find-slots');
    this.reselectDateButton = document.querySelector('#reselect-date-btn');
    this.dateSelectionCard = document.querySelector('#date-selection-card');
    this.reservationContent = document.querySelector('#reservation-content');

    this.isTimeSelected = false;
  }

  init() {
    this.#setupEventListeners();
  }

  #isSmallScreen() {
    return window.innerWidth < 768;
  }

  #setupEventListeners() {
    this.reselectDateButton?.addEventListener('click', () => {
      this.isTimeSelected = false;
      this.#toggleCardOnSmallScreen();
    });

    this.findSlotsButton?.addEventListener('click', () => {
      this.isTimeSelected = true;
      this.#toggleCardOnSmallScreen();
    });

    window.addEventListener('resize', () => {
      this.#toggleCardOnSmallScreen();
    });
  }

  #toggleCardOnSmallScreen() {
    const isSmallScreen = this.#isSmallScreen();
    if (isSmallScreen) {
      if (this.isTimeSelected) {
        this.dateSelectionCard.classList.add('d-none');
        this.reservationContent.classList.remove('d-none');
        this.reselectDateButton.classList.remove('d-none');
      } else {
        this.dateSelectionCard.classList.remove('d-none');
        this.reservationContent.classList.add('d-none');
        this.reselectDateButton.classList.add('d-none');
      }
    } else {
      this.reselectDateButton.classList.add('d-none');
      this.dateSelectionCard.classList.remove('d-none');
      this.reservationContent.classList.remove('d-none');
    }
  }
}

export default MobileView;
