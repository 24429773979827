import React from 'react';

import { ActionLinks } from './Components/ActionLinks';
import { ExamHistory } from './Components/ExamHistory';
import { ExamPageHeader } from './Components/ExamPageHeader';
import { ScheduledExams } from './Components/ScheduledExams';
import { UnscheduledExams } from './Components/UnscheduledExams';
import { MySessionsProvider, useMySessions } from './context';

/**
 * @typedef MySessionsProps
 * @property {string} guardianExtensionUrl
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 * @property {boolean} isValidUserAgent
 * @property {boolean} isUCardNeeded
 * @property {string} advanceCancellationReasonId
 * @property {User} currentUser
 * @property {boolean} shouldOpenExamFulfilledModal
 * @property {boolean} shouldOpenBrowserDownloadModal
 * @property {string} testItOutUrl
 * @property {string} faqUrl
 * @property {boolean} isUcardCreateable
 * @property {boolean} isSchedulingAllowed
 * @property {boolean} isGuardianExtensionEnabledForAny
 * @property {boolean} isESPrechecksEnabledForAny
 * @property {boolean} isLoggedFromLms
 * @property {string} timestamp
 * @property {Reservation[]} reservations
 * @property {SessionHistory} sessionHistory
 * @property {string} currentUserData
 * @property {boolean} isDevelopmentEnv
 * @property {string} studentOrderPath
 * @property {string[]} cancellationRequiredIds
 * @property {string} studentReservationUrl
 * @property {string} selectStudentExamsPath
 */

/**
 * MySessions component to display the user's sessions.
 *
 * @param {MySessionsProps} props
 * @returns {React.ReactElement}
 */
function MySessionsComponent(props) {
  const { reservations } = useMySessions();

  const scheduledReservations = reservations.filter(
    (reservation) => reservation.status === 'scheduled',
  );
  const unScheduledReservations = reservations.filter(
    (reservation) => reservation.status === 'pending',
  );

  return (
    <div className="my-sessions-v2">
      <ExamPageHeader selectStudentExamsPath={props.selectStudentExamsPath} />
      <ActionLinks {...props} />
      <ScheduledExams {...props} reservations={scheduledReservations} />
      <UnscheduledExams {...props} reservations={unScheduledReservations} />
      <ExamHistory sessionHistory={props.sessionHistory} />
    </div>
  );
}

/**
 * MySessions component wrapped with MySessionsProvider.
 *
 * @param {MySessionsProps} props
 * @returns {React.ReactElement}
 */
export default function MySessions(props) {
  return (
    <MySessionsProvider initialReservations={props.reservations}>
      <MySessionsComponent {...props} />
    </MySessionsProvider>
  );
}
