import React, { useState } from 'react';

import moment from 'moment';

import useCountdown from '../../../../hooks/useCountdown';
import { useMySessions } from '../context';
import { Countdown } from './Countdown';
import { ReservationActions } from './ReservationActions';
import { ReservationListItem } from './ReservationListItem';

/** @typedef {import('../Components/Countdown').CountdownState} CountdownState */
/** @typedef {import('../Components/Countdown').CountdownTime} CountdownTime */

/**
 * @typedef ScheduledExamsProps
 * @property {Reservation[]} reservations
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {string[]} cancellationRequiredIds
 * @property {string} advanceCancellationReasonId
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {boolean} isDevelopmentEnv
 */

/**
 * ScheduledExams component displays a list of scheduled exam reservations and a
 * countdown to the closest upcoming exam reservation.
 * @param {ScheduledExamsProps} props
 * @returns {React.ReactElement}
 */
export const ScheduledExams = ({
  reservations,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  advanceCancellationReasonId,
  guardianBrowserPlatform,
  isDevelopmentEnv,
}) => {
  const { startExam } = useMySessions();
  const upcomingReservations = reservations.filter(
    (reservation) => reservation.fulfillment.isCountdownAvailable,
  );
  const [reservationIndex, setReservationIndex] = useState(0);
  const closestUpcomingReservation = upcomingReservations[0];
  const [nextExamStartTime, setNextExamStartTime] = useState(
    closestUpcomingReservation
      ? new Date(closestUpcomingReservation.fulfillment.startsAt).getTime()
      : null,
  );

  const handleNextCountdown = () => {
    if (closestUpcomingReservation) {
      startExam(closestUpcomingReservation.uuid);
    }

    const nextReservationIndex = reservationIndex + 1;
    const nextReservation = upcomingReservations[nextReservationIndex];
    if (!nextReservation) return;

    setReservationIndex(nextReservationIndex);
    setNextExamStartTime(
      new Date(closestUpcomingReservation.fulfillment.startsAt).getTime(),
    );
  };

  const timeLeftInMilliseconds = useCountdown(nextExamStartTime, {
    onTick: () => {
      if (closestUpcomingReservation?.fulfillment?.adjustedDate) {
        if (
          new Date(closestUpcomingReservation.fulfillment.adjustedDate) <
          new Date()
        ) {
          startExam(closestUpcomingReservation.uuid);
        }
      }
    },
    onComplete: () => handleNextCountdown(),
  });
  const timeLeft = moment.duration(timeLeftInMilliseconds);

  /** @type {CountdownTime} */
  const countdownTime = {
    days: Math.floor(timeLeft.asDays()),
    hours: timeLeft.hours(),
    minutes: timeLeft.minutes(),
    seconds: timeLeft.seconds(),
  };

  /** @type {CountdownState} */
  const countdownState = closestUpcomingReservation
    ? timeLeft > 0
      ? 'countdown'
      : 'start-button'
    : '';

  return (
    <div className="card border-0">
      <div className="card-header bg-dark text-white">
        <Countdown time={countdownTime} countdownState={countdownState} />
      </div>

      <ul className="list-group list-group-flush border">
        {reservations.map((reservation) => (
          <li className="list-group-item" key={reservation.id}>
            <ReservationListItem reservation={reservation}>
              <ReservationActions
                reservation={reservation}
                studentReservationUrl={studentReservationUrl}
                studentOrderPath={studentOrderPath}
                cancellationRequiredIds={cancellationRequiredIds}
                advanceCancellationReasonId={advanceCancellationReasonId}
                guardianBrowserPlatform={guardianBrowserPlatform}
                isDevelopmentEnv={isDevelopmentEnv}
              />
            </ReservationListItem>
          </li>
        ))}
      </ul>
    </div>
  );
};
