import { useEffect } from 'react';

const SecondCameraPositioningCard = ({ sendEvent, broadcastMessage }) => {
  useEffect(() => {
    sendEvent('Event::SecondCameraPositioningLoaded');
    broadcastMessage({
      sender: 'archimedes',
      messageType: 'request_second_camera_preview',
      data: {},
    });
  }, []);

  return (
    <div id="second-camera-positioning-root">
      {/* Step contents are rendered by Guardian Browser */}
    </div>
  );
};

export default SecondCameraPositioningCard;
