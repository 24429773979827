import React from 'react';

/** @typedef {import('../../../../types').GuardianPlatform} GuardianPlatform */
/** @typedef {import('../../../../types').Reservation} Reservation */

/**
 * @typedef LaunchExamButtonProps
 * @property {Reservation} reservation
 * @property {string} advanceCancellationReasonId
 * @property {boolean} isUnsupportedPlatform
 * @property {boolean} isHidden
 * @property {string} label
 */

/**
 * @param {LaunchExamButtonProps} props
 * @returns {React.ReactElement}
 */
const LaunchExamButton = ({
  reservation,
  advanceCancellationReasonId,
  isUnsupportedPlatform,
  isHidden,
  label,
}) => {
  if (isHidden || reservation.status === 'pending') {
    return <></>;
  }

  if (isUnsupportedPlatform) {
    return (
      <div className="alert alert-primary" role="alert">
        {polyglot.t('my_sessions_v2_reservation_actions_unsupported_os_launch')}
      </div>
    );
  }

  return (
    <a
      className={`btn btn-success btn-lg start_button ${reservation.isStartDisabled ? 'disabled' : ''}`}
      exam_name={reservation.examName}
      data-custom-eligibility-enabled={reservation.isCustomEligibilityEnabled}
      data-reservation-id={reservation.id}
      data-cancellation-reason-id={advanceCancellationReasonId}
      href={reservation.startButtonDestination}
      data-toggle="tooltip"
      data-placement="top"
    >
      {label}
    </a>
  );
};

/**
 * @typedef RescheduleButtonProps
 * @property {Reservation} reservation
 * @property {boolean} isUnsupportedPlatform
 */

/**
 * @param {RescheduleButtonProps} props
 * @returns {React.ReactElement}
 */
const RescheduleButton = ({ reservation, isUnsupportedPlatform }) => {
  if (
    !reservation.isReschedulable ||
    reservation.isReconnectable ||
    reservation.status === 'pending'
  ) {
    return <></>;
  }

  return (
    <div className={isUnsupportedPlatform ? 'text-right' : 'd-inline'}>
      <a
        className="btn btn-link font-weight-bold"
        href={reservation.reschedulePath}
        data-behavior="reschedule"
        aria-label={`Reschedule for exam ${reservation.examName}`}
      >
        {polyglot.t('my_sessions_v2_reservation_actions_reschedule')}
      </a>
    </div>
  );
};

/**
 * @typedef ViewInCartProps
 * @property {string} studentOrderPath
 * @property {boolean} isUnsupportedPlatform
 */

/**
 * @param {ViewInCartProps} props
 * @returns {React.ReactElement}
 */
const ViewInCart = ({ studentOrderPath, isUnsupportedPlatform }) => {
  return (
    <div className={isUnsupportedPlatform ? 'text-right' : 'd-inline'}>
      <a className="btn btn-link font-weight-bold" href={studentOrderPath}>
        {polyglot.t('my_sessions_v2_reservation_actions_view_in_cart')}
      </a>
    </div>
  );
};

/**
 * @typedef CancelButtonProps
 * @property {Reservation} reservation
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {string[]} cancellationRequiredIds
 * @property {boolean} isUnsupportedPlatform
 */

/**
 * @param {CancelButtonProps} props
 * @returns {React.ReactElement}
 */
const CancelButton = ({
  reservation,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  isUnsupportedPlatform,
}) => {
  if (!reservation.fulfillment?.isCancellable) {
    return <></>;
  }

  const showCancelModal =
    !reservation.fulfillment?.ptc ||
    !reservation.fulfillment?.isSurchargesExist;

  return (
    <div className={isUnsupportedPlatform ? 'text-right' : 'd-inline'}>
      {showCancelModal ? (
        <a
          className={`btn btn-link font-weight-bold ${isUnsupportedPlatform ? 'text-right' : ''}`}
          href="#"
          data-toggle="modal"
          data-target="#cancel-modal"
          data-fulfillment-uuid={reservation.fulfillment.uuid}
          data-form-url={reservation.cancellationPath}
          data-stop-session={reservation.fulfillment.isRunning}
          data-hide-parent={false}
          data-comments-required-id={cancellationRequiredIds}
          data-submit-confirm={false}
          data-success-url={studentReservationUrl}
          data-first-name={reservation.fulfillment.userFirstName}
          data-exam-name={reservation.fulfillment.examName}
          data-starts-at={reservation.fulfillment.startsAtLocalized}
        >
          {polyglot.t('my_sessions_v2_reservation_actions_cancel')}
        </a>
      ) : (
        <a
          className={`btn btn-link font-weight-bold ${isUnsupportedPlatform ? 'text-right' : ''}`}
          href={studentOrderPath}
        >
          {polyglot.t('my_sessions_v2_reservation_actions_cancel')}
        </a>
      )}
    </div>
  );
};

/**
 * @typedef ReservationActionsProps
 * @property {Reservation} reservation
 * @property {string} studentReservationUrl
 * @property {string} studentOrderPath
 * @property {string[]} cancellationRequiredIds
 * @property {string} advanceCancellationReasonId
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {boolean} isDevelopmentEnv
 */

/**
 * @param {ReservationActionsProps} props
 * @returns {React.ReactElement}
 */
export const ReservationActions = ({
  reservation,
  studentReservationUrl,
  studentOrderPath,
  cancellationRequiredIds,
  advanceCancellationReasonId,
  guardianBrowserPlatform,
  isDevelopmentEnv,
}) => {
  const isUnsupportedPlatform =
    guardianBrowserPlatform !== 'MAC' && guardianBrowserPlatform !== 'WINDOWS';

  return (
    <div className="mt-4 mt-sm-4 mt-md-2">
      <LaunchExamButton
        reservation={reservation}
        advanceCancellationReasonId={advanceCancellationReasonId}
        isUnsupportedPlatform={isUnsupportedPlatform}
        isHidden={
          !isDevelopmentEnv || (reservation.fulfillment?.isAccessible ?? false)
        }
        label={polyglot.t('my_sessions_v2_reservation_actions_launch_exam')}
      />
      {reservation.fulfillment?.uuid ? (
        <>
          <LaunchExamButton
            reservation={reservation}
            advanceCancellationReasonId={advanceCancellationReasonId}
            isUnsupportedPlatform={isUnsupportedPlatform}
            isHidden={
              !reservation.fulfillment.isAccessible ||
              reservation.fulfillment.ptc
            }
            label={
              reservation.isReconnectable
                ? polyglot.t('my_sessions_v2_reservation_actions_reconnect')
                : polyglot.t('my_sessions_v2_reservation_actions_launch_exam')
            }
          />
          {reservation.fulfillment.isAccessible ? (
            <RescheduleButton
              reservation={reservation}
              isUnsupportedPlatform={isUnsupportedPlatform}
            />
          ) : reservation.isOnOpenOrder ? (
            <ViewInCart
              studentOrderPath={studentOrderPath}
              isUnsupportedPlatform={isUnsupportedPlatform}
            />
          ) : (
            <div className="d-inline-flex">
              <RescheduleButton
                reservation={reservation}
                isUnsupportedPlatform={isUnsupportedPlatform}
              />
              <CancelButton
                reservation={reservation}
                studentReservationUrl={studentReservationUrl}
                studentOrderPath={studentOrderPath}
                cancellationRequiredIds={cancellationRequiredIds}
                isUnsupportedPlatform={isUnsupportedPlatform}
              />
            </div>
          )}
        </>
      ) : reservation.isOnOpenOrder ? (
        <ViewInCart
          studentOrderPath={studentOrderPath}
          isUnsupportedPlatform={isUnsupportedPlatform}
        />
      ) : (
        <a
          className="btn btn-link font-weight-bold"
          href={reservation.editStudentReservationPath}
        >
          {polyglot.t('my_sessions_v2_reservation_actions_schedule')}
        </a>
      )}
    </div>
  );
};
