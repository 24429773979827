import React, { createContext, useContext, useState } from 'react';

/** @typedef {import('../../../types').Reservation} Reservation */

/**
 * @typedef MySessionsContextValue
 * @property {Reservation[]} reservations
 * @property {(reservationUuid: string) => void} startExam
 * @property {(reservations: Reservation[]) => void} setReservations
 */

/**
 * @type {React.Context<MySessionsContextValue>}
 */
export const MySessionsContext = createContext();

/**
 * MySessionsProvider component to provide the MySessionsContext value.
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {Reservation[]} props.initialReservations
 * @returns {React.ReactElement}
 */
export const MySessionsProvider = ({ children, initialReservations }) => {
  const [reservations, setReservations] = useState(initialReservations);

  /**
   * Start an exam by updating the reservation state.
   * @param {string} reservationUuid - The uuid of the reservation to start.
   */
  const startExam = (reservationUuid) => {
    setReservations((prevReservations) =>
      prevReservations.map((reservation) =>
        reservation.uuid === reservationUuid
          ? {
              ...reservation,
              fulfillment: { ...reservation.fulfillment, isAccessible: true },
            }
          : reservation,
      ),
    );
  };

  return (
    <MySessionsContext.Provider
      value={{ reservations, setReservations, startExam }}
    >
      {children}
    </MySessionsContext.Provider>
  );
};

/**
 * Custom hook to use the MySessionsContext.
 * @returns {MySessionsContextValue}
 */
export function useMySessions() {
  return useContext(MySessionsContext);
}
