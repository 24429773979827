import axios from 'axios';

import csrfToken from '../../../src/utils/csrf';

const apiClient = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const apiPost = async (url, data) => {
  return await apiClient.post(url, data, {
    headers: {
      // NOTE: always include the CSRF token from the DOM to prevent CSRF attacks
      'X-CSRF-Token': csrfToken(),
    },
  });
};
