import Toast from '../../utils/Toast';
import titleize from '../../utils/titleize';

class AccessCodeMessage {
  constructor(name) {
    this.name = name
  }

  toast(type) {
    let text, status = '';
    switch (type) {
      case 'required':
        text = `Please enter an ${this.name} code.`;
        status = 'warning';
        break;
      case 'success':
        text = `Your ${this.name} code was successfully added to your order.`;
        status = 'success';
        break;
      case 'expired':
        text = `Sorry, your ${this.name} code has expired.`;
        status = 'danger';
        break;
      case 'notfound':
        text = `Sorry, your ${this.name} code was not found.`;
        status = 'warning';
        break;
      case 'used':
        text = `Sorry, your ${this.name} code has already been used.`;
        status = 'warning';
        break;
      case 'invalid':
        text = `Sorry, your ${this.name} code can not be applied in this situation.`;
        status = 'warning';
        break;
      case 'appointmentAlreadyFree':
        text = `Appointment is already $0, Voucher cannot be applied.`;
        status = 'danger';
        break;
      case 'nothing':
        text = '';
    }
    new Toast().sendToNotify(status, { message: text });
  }

  used() {
    return `${titleize(this.name)} Code used`
  }
}

export default AccessCodeMessage
