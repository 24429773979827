import React from 'react';

import ExamResourcesCard from '../Exam/ExamResourcesCard';
import OrderDetails from './OrderDetails';

/** @typedef {import('../../types').GuardianDownloadUrls} GuardiwnDownloadUrls */
/** @typedef {import('../../types').GuardianPlatform} GuardianPlatform */
/** @typedef {import('../../types').LineItem} LineItem */
/** @typedef {import('../../types').Order} Order */
/** @typedef {import('../../types').PaymentInformation} PaymentInformation */

/**
 * @typedef OrderReceiptProps
 * @property {string} printOrderPath
 * @property {string} supportUrl
 * @property {boolean} isGuardianExtension
 * @property {string} guardianExtensionUrl
 * @property {boolean} isGuardianBrowser
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {GuardiwnDownloadUrls} guardianBrowserDownloadUrls
 * @property {Order} order
 * @property {LineItem[]} lineItems
 * @property {PaymentInformation} [paymentInformation]
 */

/**
 * @param {OrderReceiptProps} props
 * @returns {React.ReactElement}
 */
export default function OrderReceipt(props) {
  const userWasCharged = props.order.chargeAmount > 0;

  return (
    <>
      <div className="d-flex flex-column">
        <Header printOrderPath={props.printOrderPath} />

        <div className="row">
          <div className="col-12 col-lg-8 order-1 order-lg-0 mt-4 mt-lg-0">
            <ExamResourcesCard
              isGuardianBrowser={props.isGuardianBrowser}
              isGuardianExtension={props.isGuardianExtension}
              guardianBrowserPlatform={props.guardianBrowserPlatform}
              guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
              guardianExtensionUrl={props.guardianExtensionUrl}
              supportUrl={props.supportUrl}
            />
          </div>

          <div className="col-12 col-lg-4 order-0 order-lg-1">
            <OrderDetails
              order={props.order}
              lineItems={props.lineItems}
              paymentInformation={props.paymentInformation}
              userWasCharged={userWasCharged}
            />
          </div>
        </div>
      </div>
    </>
  );
}

/**
 * @param {Object} props
 * @param {string} props.printOrderPath
 * @returns {React.ReactElement}
 */
function Header({ printOrderPath }) {
  return (
    <div className="d-flex justify-content-between flex-wrap mb-4">
      <h1 className="fs-3">{polyglot.t('order_receipt_order_success')}</h1>
      <a
        href={printOrderPath}
        target="_blank"
        rel="noreferrer"
        className="btn btn-secondary font-weight-bold"
      >
        {polyglot.t('order_receipt_button_print')}
      </a>
    </div>
  );
}
