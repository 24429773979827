// AdministratorDashboard
//
// This class handles initializing the Administrator Dashboard view
// retrieved from Tableau or Power BI

const CLIENT_PORTAL = {
  dataset_id: 'a45d3614-b0a5-4a9c-b6ea-b68d3feeb67d',
  report_id: 'f4b1b302-e3ce-4f1d-a78b-5d35446fb70c'
}

class AdministratorDashboard {
  constructor() {
    this.containerDiv = document.getElementById('vizContainer');
    // Get the value of power_bi_flipper from the hidden field and set usePowerBI
    this.usePowerBI = document.getElementById('power_bi_flipper').value === 'true';
  }

  async init() {
    if (this.usePowerBI) {
      await this.loadPowerBIDashboard();
    } else {
      this.loadTableauDashboard();
    }
  }

  async loadPowerBIDashboard() {
    if (window['powerbi-client']) {
      const models = window['powerbi-client'].models;
      const institutionId = document.getElementById('institution_uuid').value;
      const userId = document.getElementById('user_id').value;
      const termId = document.getElementById('term_id').value;
      const embedContainer = document.getElementById("embedContainer");

      try {
        const response = await fetch(
          `/api/power_bi/get_embed_data?institution_uuid=${institutionId}&user_id=${userId}&term_id=${termId}&dashboard_type=administrator_dashboards&report_id=${CLIENT_PORTAL.report_id}&dataset_id=${CLIENT_PORTAL.dataset_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        let embedConfig = {
          type: "report",
          tokenType: models.TokenType.Embed,
          accessToken: data.embed_token,
          embedUrl: data.embed_url,
          id: CLIENT_PORTAL.report_id,
          permissions: models.Permissions.View,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
          },
        };

        powerbi.embed(embedContainer, embedConfig);
        embedContainer.addEventListener('embed-error', (event) => {
          console.error('Power BI Embed Error:', event.detail);
        });
      } catch (error) {
        console.error("Fetch error:", error.message);
      }
    } else {
      console.error("Power BI could not be initialized");
    }
  }

  loadTableauDashboard() {
    const { width, height } = this.getContainerDimensions();
    const url = this.getDashboardUrl();

    const options = {
      hideTabs: false,
      hideToolbar: true,
      width: width + 'px',
      height: height + 'px',
      onFirstInteractive: () => {}
    };

    if (typeof tableau === 'undefined' || typeof tableau.Viz === 'undefined') {
      location.reload();
    } else {
      new tableau.Viz(this.containerDiv, url, options);
    }
  }

  getContainerDimensions() {
    return {
      width: this.containerDiv.offsetWidth,
      height: this.containerDiv.offsetHeight
    };
  }

  getDashboardUrl() {
    const institutionId = document.getElementById('institution_uuid').value;
    const userId = document.getElementById('user_id').value;
    const termId = document.getElementById('term_id').value;
    const authorizedTicket = document.getElementById('authorized_ticket').value;

    let url =
      `https://tableau-ext.proctoru.com` +
      `/trusted/${authorizedTicket}` +
      `/views/AdminDashboard/Summary` +
      `?Institution UUID=${institutionId}` +
      `&User ID=${userId}`;

    if (termId) {
      url += `&Term ID=${termId}`;
    }

    return url;
  }
}

export default AdministratorDashboard;
