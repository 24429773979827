import { useCallback } from 'react';

import { mapKeys, snakeCase } from 'lodash';

import { apiPost } from '../api';

export default function usePrechecksApi(fulfillmentUuid) {
  const sendEvent = useCallback(
    async (
      eventType,
      payload = {},
      eventEndpointType = 'prechecks',
      responseFunction,
      errorFunction,
    ) => {
      const eventPayload = {
        uuid: fulfillmentUuid,
        event: {
          type: eventType,
          ...payload,
        },
      };

      try {
        console.log(`Sending ${eventType} event:`, eventPayload);
        const response = await apiPost(
          `${window.location.origin}/api/secure_browser/events/${eventEndpointType}`,
          mapKeys(eventPayload, (value, key) => snakeCase(key)),
        );
        console.log(response);
        responseFunction?.(response);
      } catch (error) {
        console.log(error);
        errorFunction?.(error);
      }
    },
    [fulfillmentUuid],
  );

  const broadcastMessage = useCallback(
    async (message) => {
      try {
        const response = await apiPost(
          `${window.location.origin}/api/secure_browser/fulfillments/${fulfillmentUuid}/broadcasting`,
          { msg: JSON.stringify(message) },
        );
        console.log('Message sent:', response);
      } catch (error) {
        console.log(error);
      }
    },
    [fulfillmentUuid],
  );

  return { sendEvent, broadcastMessage };
}
