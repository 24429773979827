import React from 'react';

import DownloadGuardianBrowser from './DownloadGuardianBrowser';
import DownloadGuardianExtension from './DownloadGuardianExtension';

/** @typedef {import('../../types').GuardianDownloadUrls} GuardianDownloadUrls */
/** @typedef {import('../../types').GuardianPlatform} GuardianPlatform */

/**
 * @typedef ExamResourcesCardProps
 * @property {boolean} isGuardianBrowser
 * @property {boolean} isGuardianExtension
 * @property {GuardianPlatform} guardianBrowserPlatform
 * @property {GuardianDownloadUrls} guardianBrowserDownloadUrls
 * @property {string} guardianExtensionUrl
 * @property {string} supportUrl
 */

/**
 * @param {ExamResourcesCardProps} props
 * @returns {React.ReactElement}
 */
export default function ExamResourcesCard(props) {
  const isValidPlatform = props.guardianBrowserPlatform !== 'UNSUPPORTED';

  return (
    <>
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h2 className="fs-5 mb-0">{polyglot.t('order_receipt_resources')}</h2>
        </div>

        <div className="card-body d-flex flex-column" style={{ gap: '1.5rem' }}>
          {isValidPlatform && props.isGuardianBrowser && (
            <DownloadGuardianBrowser
              guardianBrowserPlatform={props.guardianBrowserPlatform}
              guardianBrowserDownloadUrls={props.guardianBrowserDownloadUrls}
            />
          )}

          {isValidPlatform && props.isGuardianExtension && (
            <DownloadGuardianExtension
              guardianExtensionUrl={props.guardianExtensionUrl}
            />
          )}

          <div>
            <h3 className="fs-5 card-title mb-0">
              <a href={props.supportUrl} target="_blank">
                <i className="fa-solid fa-book mr-2"></i>
                {polyglot.t('order_receipt_support_text')}
              </a>
            </h3>
            <p className="card-text">
              {polyglot.t('order_receipt_support_description')}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
